// eslint-disable-next-line no-restricted-imports
import { TeaserLabelProps } from '@sport1/news-styleguide/core/types'
import dayjs from 'dayjs'
import { parsePublishedAtDate } from '@/helpers/dateHelper'

export const getStreamStatus = (startTime: string, endTime: string): 'PRE' | 'LIVE' | 'POST' => {
    const now = dayjs().locale('de')
    if (parsePublishedAtDate(startTime).isAfter(now)) {
        return 'PRE'
    } else if (parsePublishedAtDate(endTime).isAfter(now)) {
        return 'LIVE'
    }
    return 'POST'
}
export const getStreamTeaserLabels = ({
    isHighlight,
    startTime,
    endTime,
    paymentInformation,
}: {
    isHighlight?: boolean
    startTime: string
    endTime: string
    paymentInformation?: 'PAID' | 'FREE' | 'REGISTRATION'
}): [TeaserLabelProps] | [TeaserLabelProps, TeaserLabelProps] => {
    const labels: [TeaserLabelProps] | [TeaserLabelProps, TeaserLabelProps] = [
        { labelType: 'pearl', labelText: paymentInformation === 'PAID' ? 'PAY' : 'FREE' },
    ]
    const status = getStreamStatus(startTime, endTime)
    if (status === 'PRE') {
        if (isHighlight) {
            labels.unshift({ labelType: 'onyx', labelText: 'Highlight' })
        }
    } else if (status === 'LIVE') {
        labels.unshift({ labelType: 'live' })
    } else {
        labels.unshift({ labelType: 'onyx', labelText: 're-live' })
    }
    return labels
}
