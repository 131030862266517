import React, { FC, useCallback, useMemo } from 'react'
import NonFlexingContainer from '@sport1/news-styleguide/NonFlexingContainer'
import { EventStreamTeaserProps } from '@sport1/types/web'
import { RenderListItemProps } from '@sport1/news-styleguide/Types'
import Card from '../Card'
import HorizontalSlider from '@/components/HorizontalSlider'
import EventStreamTeaser from '@/components/UpcomingEventStreams/Teaser'
import Config from '@/utils/Config'
import { useTracking } from '@/utils/tracking/TrackingProvider'

export type EventCardHeadlineProps = {
    hint?: string
    title: string
    iconUrl?: string
    link: string
}

type Props = {
    eventStreamTeaser: EventStreamTeaserProps[]
    layoutType?: 'STANDARD' | 'TV'
    cardHeaderProps: EventCardHeadlineProps
}

const UpcomingEventStreams: FC<Props> = ({
    eventStreamTeaser,
    cardHeaderProps,
    layoutType = 'STANDARD',
}) => {
    const { hint, title, link } = cardHeaderProps
    const isStandardLayout = useMemo(() => layoutType === 'STANDARD', [layoutType])
    const keyExtractor = useCallback(
        (item: EventStreamTeaserProps) => `${item.episodeTitle}_${item.startTime}`,
        []
    )
    const { trackInteraction } = useTracking()

    const onArrowPress = useCallback(() => {
        trackInteraction({
            interaction_category: 'outbound_link',
            interaction_action: 'click',
            interaction_label: link,
        })
    }, [link, trackInteraction])

    const renderItem = useCallback(
        ({
            item: {
                id,
                meta,
                tag,
                startTime,
                endTime,
                programTitle,
                emissionType,
                imageUrl,
                episodeTitle,
                link,
                paymentInformation,
                isHighlight,
            },
        }: RenderListItemProps<EventStreamTeaserProps>) => (
            <EventStreamTeaser
                id={id}
                meta={meta}
                tag={tag}
                startTime={startTime}
                endTime={endTime}
                programTitle={programTitle}
                emissionType={emissionType}
                imageUrl={imageUrl}
                episodeTitle={episodeTitle}
                paymentInformation={paymentInformation}
                isHighlight={isHighlight}
                link={link || `${Config.HOST_TV}/live/event/${id}`}
            />
        ),
        []
    )

    return (
        <NonFlexingContainer
            testID="upcoming-event-streams-row"
            marginBottom={
                isStandardLayout
                    ? ['spacing-7', 'spacing-7', 'spacing-7', 'spacing-9']
                    : 'spacing-none'
            }
        >
            <Card
                title={title}
                titleHref={link}
                onPress={onArrowPress} // triggers page_referring_widget
                hint={hint}
                testID="upcoming-event-streams"
            />
            <NonFlexingContainer
                marginBottom={
                    isStandardLayout ? ['spacing-6', 'spacing-6', 'spacing-9'] : 'spacing-9'
                }
                testID="upcoming-event-streams-slider-container"
            >
                <HorizontalSlider<EventStreamTeaserProps>
                    itemWidth={290}
                    items={eventStreamTeaser}
                    keyExtractor={keyExtractor}
                    renderItem={renderItem}
                    spaceX={
                        isStandardLayout
                            ? ['standard', 'medium', 'big']
                            : ['standard', 'standard', 'standard', 'big']
                    }
                    backgroundColor="TRANSPARENT"
                    arrowMargin="spacing-10"
                />
            </NonFlexingContainer>
        </NonFlexingContainer>
    )
}

export default UpcomingEventStreams
