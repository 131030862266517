import { TrackingDataProps } from '@sport1/types/web'
import { MappedTrackingData, mapTrackingData } from '@/utils/tracking/TrackingProvider'

type Props = {
    tracking?: TrackingDataProps[]
    labelType?: string
    link: string
}

export const mapAndHydrateTrackingData = ({
    tracking,
    link,
    labelType,
}: Props): MappedTrackingData => {
    const result = mapTrackingData(tracking)
    // add target_url (normally provided by BE)
    result['target_url'] = link
    // add advertiser_id (if not already there)
    if (!('advertiser_id' in result)) {
        result['advertiser_id'] = link.includes('sport1extra') ? 'sport1 extra' : 'sport1'
    }
    // add / edit campaign
    const campaign = result['campaign']
    const labelExtension = labelType ? ` ${labelType}` : ''
    if (!campaign) {
        result['campaign'] = `external |${labelExtension}`
    } else {
        if (campaign?.includes('|')) {
            const [parent, child] = campaign.split('|')
            if (child === '' || child === ' ') {
                result['campaign'] = `${parent}|${labelExtension}`
            }
        } else {
            result['campaign'] = `${campaign} |${labelExtension}`
        }
    }
    return result
}
